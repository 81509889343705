<script setup lang="ts">
const {
	public: { fbId }
} = useRuntimeConfig();

const props = withDefaults(
	defineProps<{
		referralUrl: string;
	}>(),
	{
		referralUrl: ""
	}
);
const handleClickFb = (url: string) => {
	const fbData = {
		appId: fbId,
		redirectUri: encodeURIComponent(url),
		display: "iframe",
		href: encodeURIComponent(url)
	};
	window.location.href = `https://www.facebook.com/dialog/share?app_id=${fbData.appId}&display=${fbData.display}&href=${fbData.href}&redirect_uri=${fbData.redirectUri}`;
};

const handleClickWhatsApp = (url: string) => {
	window.location.href = `https://wa.me/?text=${url}`;
};
</script>

<template>
	<div class="d-flex gap-4">
		<AButton
			variant="facebook"
			size="s"
			class="button"
			data-tid="share-link-in-fb"
			@click.stop="handleClickFb(props.referralUrl)"
		>
			<NuxtIcon name="20/fb" class="icon" />
		</AButton>
		<AButton
			v-if="props.referralUrl"
			variant="whatsapp"
			size="s"
			class="button"
			data-tid="share-link-in-whatsapp"
			@click.stop="handleClickWhatsApp(props.referralUrl)"
		>
			<NuxtIcon name="20/whatsapp" class="icon" />
		</AButton>
	</div>
</template>

<style scoped lang="scss">
.icon {
	font-size: 20px;
}
</style>
